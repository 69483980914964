import moment from 'moment';
import chartOptions from '../chart/chart_options';
import common from '../component/common.js';
import _date from '../component/date.js';

const set_device_minute_data = (_rawData, measure, text, chartTypeModel,
  deviceModelType, deviceList, stepTime) => new Promise((resolve) => {
  const sortType = 'datetimestamp';


  const res = _rawData.data.sort((a, b) => {
    if (a[sortType] < b[sortType]) return -1;
    if (a[sortType] > b[sortType]) return 1;
    return 0;
  });
 
  const tempTimeData = {};
  res.forEach((item) => {

    let hh;
    let mm;
    let device = item[deviceModelType];
    item.Hour < 10 ? hh = `0${item.Hour}` : hh = `${item.Hour}`;
    item.MinuteGrup < 10 ? mm = `0${item.MinuteGrup}` : mm = `${item.MinuteGrup}`;
    const date = `${hh}:${mm}`;

    if (!tempTimeData[device]) {
      tempTimeData[device] = {};
    }
    tempTimeData[device][date] = item;
  });

  const desserts = [];
  const dessert = {};
  const series = [];
  const result = {};
  const yAxis = [];
  const timeList = [];
  let index = 0;

  deviceList.forEach((device) => {


    const tempData = [];
    const total = 0;
    const minute = 0;
    const hour = 5;

    for (let i = hour; i <= 20; i++) {
      for (let x = minute; x < 60; x += stepTime) {
        let h, m;

        i < 10 ? h = `0${i}` : h = `${i}`;
        x < 10 ? m = `0${x}` : m = `${x}`;
        const time = `${h}:${m}`;
        if (timeList.indexOf(time) < 0) {
          timeList.push(time);
        }

        const obj = {};
        obj.date = time;

        let tmp_val = 0;

        if (tempTimeData.hasOwnProperty(device)) {
          if (tempTimeData[device].hasOwnProperty(time)) {
            const tempValue = tempTimeData[device][time];
            tmp_val = common.setMeasures(measure, tempValue, total);
          }
        }

        tempData.push(tmp_val);

        obj[device] = tmp_val;
        dessert[time] = {
          ...dessert[time],
          ...obj,
        };
      }
    }

    const obj = {
      name: `${device} ${measure.text}(${measure.unit})`,
      data: tempData,
      type: chartTypeModel,
    };

    const yaxis = {
      type: 'value',
      name: `${measure.text}(${measure.unit})`,
    };
    if (index > 0) {
      yaxis.show = false;
    }

    yAxis.push(yaxis);
    index++;
    series.push(obj);
  });

  Object.keys(dessert).forEach((key) => {
    desserts.push(dessert[key]);
  });

  const options = chartOptions.line_options_multies_1(text, deviceList,
    timeList, series, common.colors(), yAxis);

  result.options = options;
  result.desserts = desserts;

  resolve(result);

});

const set_device_hourly_data = (_rawData, measure, text, chartTypeModel,
  deviceModelType, deviceList) => new Promise((resolve) => {

  const tempTimeData = {};
  _rawData.data.forEach((item) => {
    let hh;

    let device = item[deviceModelType];
    item.Hour < 10 ? hh = `0${item.Hour}` : hh = `${item.Hour}`;

    const date = `${hh}`;

    if (!tempTimeData[device]) {
      tempTimeData[device] = {};
    }
    tempTimeData[device][date] = item;
  });

  const desserts = [];
  const dessert = {};
  const series = [];
  const result = {};
  const yAxis = [];
  const timeList = [];
  let index = 0;

  deviceList.forEach((device) => {
    const tempData = [];
    const total = 0;
    const hour = 5;

    for (let i = hour; i <= 20; i++) {
      let time;
      i < 10 ? time = `0${i}` : time = `${i}`;

      const obj = {};
      obj.date = time;

      if (timeList.indexOf(time) < 0) {
        timeList.push(time);
      }
      let tmp_val = 0;

      if (tempTimeData.hasOwnProperty(device)) {
        if (tempTimeData[device].hasOwnProperty(time)) {
          const tempValue = tempTimeData[device][time];
          tmp_val = common.setMeasures(measure, tempValue, total);
        }

        tempData.push(tmp_val);

        obj[device] = tmp_val;
        dessert[time] = {
          ...dessert[time],
          ...obj,
        };
      }
    }

    const obj = {
      name: `${device} ${measure.text}(${measure.unit})`,
      data: tempData,
      type: chartTypeModel,
    };

    const yaxis = {
      type: 'value',
      name: `${measure.text}(${measure.unit})`,
    };
    if (index > 0) {
      yaxis.show = false;
    }

    yAxis.push(yaxis);

    index++;
    series.push(obj);
  });

  Object.keys(dessert).forEach((key) => {
    desserts.push(dessert[key]);
  });
  const options = chartOptions.line_options_multies_1(text, deviceList,
    timeList, series, common.colors(), yAxis);

  result.options = options;
  result.desserts = desserts;

  resolve(result);

});

const set_device_daily_data = (_rawData, measure, text, chartTypeModel,
  reportTypeModel, deviceModelType, deviceList, startDate) => new Promise((resolve) => {

  const tempTimeData = {};
  const timeList = [];
  const sortType = common.setSortType(reportTypeModel);

  const res = _rawData.data.sort((a, b) => {
    if (a[sortType] < b[sortType]) return -1;
    if (a[sortType] > b[sortType]) return 1;
    return 0;
  });

  res.forEach((item) => {
    let mm, dd;

    let device = item[deviceModelType];
    item.Month < 10 ? mm = `0${item.Month}` : mm = `${item.Month}`;
    item.Day < 10 ? dd = `0${item.Day}` : dd = `${item.Day}`;

    const date = `${item.Year}-${mm}-${dd}`;

    if (!tempTimeData[device]) {
      tempTimeData[device] = {};
    }
    tempTimeData[device][date] = item;
  });

  const desserts = [];
  const dessert = {};
  const series = [];
  const result = {};
  const yAxis = [];
  let index = 0;

  deviceList.forEach((device) => {
    const tempData = [];
    const total = 0;

    let cur_date;

    for (let d = 1; d < 32; d++) {
      cur_date = moment(startDate).add(d, 'day').format('YYYY-MM-DD');
      if (index == 0) {
        timeList.push(cur_date);
      }

      const objx = {};
      let tmp_val = 0;
      objx.date = cur_date;

      if (tempTimeData.hasOwnProperty(device)) {
        if (tempTimeData[device].hasOwnProperty(cur_date)) {
          const tempValue = tempTimeData[device][cur_date];
          tmp_val = common.setMeasures(measure, tempValue, total);
        }
      }

      tempData.push(tmp_val);

      objx[device] = tmp_val;
      dessert[cur_date] = {
        ...dessert[cur_date],
        ...objx,
      };
    }

    const obj = {
      name: `${device} ${measure.text}(${measure.unit})`,
      data: tempData,
      type: chartTypeModel,
    };

    const yaxis = {
      type: 'value',
      name: `${measure.text}(${measure.unit})`,
    };
    if (index > 0) {
      yaxis.show = false;
    }

    yAxis.push(yaxis);
    index++;
    series.push(obj);
  });

  Object.keys(dessert).forEach((key) => {
    desserts.push(dessert[key]);
  });

  const options = chartOptions.line_options_multies_1(text, deviceList,
    timeList, series, common.colors(), yAxis);

  result.options = options;
  result.desserts = desserts;

  resolve(result);

});


const set_device_monthly_data = (_rawData, measure, text, chartTypeModel,
  reportTypeModel, deviceModelType, deviceList, dateList) => new Promise((resolve) => {

  const tempTimeData = {};
  const sortType = common.setSortType(reportTypeModel);

  const res = _rawData.data.sort((a, b) => {
    if (a[sortType] < b[sortType]) return -1;
    if (a[sortType] > b[sortType]) return 1;
    return 0;
  });

  res.forEach((item) => {
    let mm;
    let device = item[deviceModelType];
    item.Month < 10 ? mm = `0${item.Month}` : mm = `${item.Month}`;
    const date = `${item.Year}-${mm}`;

    if (!tempTimeData[device]) {
      tempTimeData[device] = {};
    }
    tempTimeData[device][date] = item;
  });

  const desserts = [];
  const dessert = {};
  const series = [];
  const result = {};
  const yAxis = [];
  let index = 0;
  const timeList = [];

  deviceList.forEach((device) => {
    const tempData = [];
    const total = 0;


    dateList.forEach((item) => {
      item.month.forEach((m) => {
        const _m = m < 10 ? `0${m}` : m;
        const cur_date = `${item.year}-${_m}`;

        if (timeList.indexOf(timeList) === -1) {
          timeList.push(cur_date);
        }

        const objx = {};
        objx.date = cur_date;
        let tmp_val = 0;

        if (tempTimeData.hasOwnProperty(device)) {
          if (tempTimeData[device].hasOwnProperty(cur_date)) {
            const tempValue = tempTimeData[device][cur_date];
            tmp_val = common.setMeasures(measure, tempValue, total);
          }
        }

        tempData.push(tmp_val);
        objx[device] = tmp_val;
        dessert[cur_date] = {
          ...dessert[cur_date],
          ...objx,
        };
      });
    });

    const obj = {
      name: `${device} ${measure.text}(${measure.unit})`,
      data: tempData,
      type: chartTypeModel,
    };

    const yaxis = {
      type: 'value',
      name: `${measure.text}(${measure.unit})`,
    };
    if (index > 0) {
      yaxis.show = false;
    }

    yAxis.push(yaxis);
    index++;
    series.push(obj);
  });

  Object.keys(dessert).forEach((key) => {
    desserts.push(dessert[key]);
  });

  const uniqtime = [];
  timeList.forEach((d) => {
    if (uniqtime.indexOf(d) === -1) {
      uniqtime.push(d);
    }
  });

  const options = chartOptions.line_options_multies_1(text, deviceList,
    uniqtime, series, common.colors(), yAxis);

  result.options = options;
  result.desserts = desserts;

  resolve(result);

});

const set_device_yearly_data = (_rawData, measure, text, chartTypeModel,
  reportTypeModel, deviceModelType, deviceList, yearList) => new Promise((resolve) => {


  const tempTimeData = {};
  const sortType = common.setSortType(reportTypeModel);

  const res = _rawData.data.sort((a, b) => {
    if (a[sortType] < b[sortType]) return -1;
    if (a[sortType] > b[sortType]) return 1;
    return 0;
  });

  res.forEach((item) => {
    let device = item[deviceModelType];

    const date = item.Year;

    if (!tempTimeData[device]) {
      tempTimeData[device] = {};
    }
    tempTimeData[device][date] = item;
  });

  const desserts = [];
  const dessert = {};
  const series = [];
  const result = {};
  const yAxis = [];
  let index = 0;
  const timeList = [];

  deviceList.forEach((device) => {
    const tempData = [];
    const total = 0;

    yearList.forEach((item) => {

      const cur_date = item;

      if (timeList.indexOf(timeList) === -1) {
        timeList.push(cur_date);
      }

      const objx = {};
      objx.date = cur_date;
      let tmp_val = 0;

      if (tempTimeData.hasOwnProperty(device)) {
        if (tempTimeData[device].hasOwnProperty(cur_date)) {
          const tempValue = tempTimeData[device][cur_date];
          tmp_val = common.setMeasures(measure, tempValue, total);
        }
      }

      tempData.push(tmp_val);
      objx[device] = tmp_val;
      dessert[cur_date] = {
        ...dessert[cur_date],
        ...objx,
      };

    });

    const obj = {
      name: `${device} ${measure.text}(${measure.unit})`,
      data: tempData,
      type: chartTypeModel,
    };

    const yaxis = {
      type: 'value',
      name: `${measure.text}(${measure.unit})`,
    };
    if (index > 0) {
      yaxis.show = false;
    }

    yAxis.push(yaxis);
    index++;
    series.push(obj);
  });

  Object.keys(dessert).forEach((key) => {
    desserts.push(dessert[key]);
  });

  const uniqtime = [];
  timeList.forEach((d) => {
    if (uniqtime.indexOf(d) === -1) {
      uniqtime.push(d);
    }
  });

  const options = chartOptions.line_options_multies_1(text, deviceList,
    uniqtime, series, common.colors(), yAxis);

  result.options = options;
  result.desserts = desserts;

  resolve(result);

});
export default {
  set_device_minute_data,
  set_device_hourly_data,
  set_device_daily_data,
  set_device_monthly_data,
  set_device_yearly_data
};
